module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Burn your kitchen!","short_name":"Burn your kitchen!","description":"Сервіс для пошуку та порівняння цін товарів для дому","scope":"/","lang":"uk","start_url":"/?source=pwa","display":"standalone","background_color":"#000000","theme_color":"#000000","icon":"src/images/icons/icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"355a6ebf5cb9c5974f8c66d123605d00"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Q0ZPM3DEED","UA-71098312-1"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":["/preview/**"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
